import { S3Upload } from '@component/S3Upload';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardBody, CardFooter, Input } from '@material-tailwind/react';
import type { AnnouncementFormData } from '@modules/websiteadmin/redux/announcementSlice';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

type IFormProps = {
	shortDescValue?: string;
	descValue?: string;
	titleValue?: string;
	videoUrlValue?: string;
	image1Value: string;
	contactEmailValue?: string;
	contactPersonValue?: string;
	scheduleTimeValue?: string;
	onSubmit: (data: AnnouncementFormData) => void;
};

const schema = Yup.object().shape({
	image1: Yup.string().required('Title is required'),
	shortDescription: Yup.string(),
	description: Yup.string(),
	title: Yup.string(),
	videoUrl: Yup.string(),
	contactEmail: Yup.string(),
	contactPerson: Yup.string(),
	scheduleTime: Yup.string(),
});

const AnnouncementForm = (props: IFormProps) => {
	const defaultValues: AnnouncementFormData = {
		title: props.titleValue,
		shortDescription: props.shortDescValue,
		description: props.descValue,
		videoUrl: props.videoUrlValue,
		image1: props.image1Value,
		contactEmail: props.contactEmailValue,
		contactPerson: props.contactPersonValue,
		scheduleTime: props.scheduleTimeValue,
	};

	const {
		getValues,
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<AnnouncementFormData>({
		resolver: yupResolver(schema),
		defaultValues,
	});

	useEffect(() => {
		reset(defaultValues);
	}, [props]);

	const navigate = useNavigate();
	return (
		<Card className="h-full w-full">
			<form
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onSubmit={handleSubmit(props.onSubmit)}
				className="mt-8 mb-2"
			>
				<CardBody className="overflow-scroll px-5">
					<div className="grid grid-cols-2 gap-4">
						<div className="mb-4 flex flex-col gap-4">
							<S3Upload onSuccess={(url, _id) => reset({ ...getValues(), image1: url })} />
							{/* <Input
								size="lg"
								label="Image Url"
								{...register('image1')}
								crossOrigin=""
								error={errors.image1 != null}
							/> */}
							<Input
								size="lg"
								label="Title"
								{...register('title')}
								crossOrigin=""
								error={errors.title != null}
							/>
							<Input
								size="lg"
								label="Short Description"
								{...register('shortDescription')}
								crossOrigin=""
								error={errors.shortDescription != null}
							/>
							<Input
								size="lg"
								label="Description"
								{...register('description')}
								crossOrigin=""
								multiple
								error={errors.description != null}
							/>
							<Input
								size="lg"
								label="Youtube Video Url"
								{...register('videoUrl')}
								crossOrigin=""
								error={errors.videoUrl != null}
							/>
							<Input
								size="lg"
								label="Contact Email"
								{...register('contactEmail')}
								crossOrigin=""
								error={errors.contactEmail != null}
							/>
							<Input
								size="lg"
								label="Contact Person"
								{...register('contactPerson')}
								crossOrigin=""
								error={errors.contactPerson != null}
							/>
							<Input
								type="datetime-local"
								size="lg"
								label="Schedule Time"
								{...register('scheduleTime')}
								crossOrigin=""
								error={errors.scheduleTime != null}
							/>
						</div>
						<div>
							<img src={getValues('image1')} className="w-full h-30" />
						</div>
					</div>
				</CardBody>
				<CardFooter className="flex">
					<Button color="green" className="mt-6 mr-2" type="submit">
						Register
					</Button>
					<Button className="mt-6" onClick={() => navigate(-1)}>
						Cancel
					</Button>
				</CardFooter>
			</form>
		</Card>
	);
};

export default AnnouncementForm;
