/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { left, right } from '@core/Either';
import { Result } from '@core/Result';
import type { APIResponse } from '@infra/services/APIResponse';
import { BaseAPI } from '@infra/services/BaseAPI';

import type { AnnouncementDTO } from '../dtos/AnnouncementDTO';
import { AnnouncementDTOMapper } from '../mappers/announcementMap';
import type { Announcement } from '../models/Announcement';

export type IStudentService = {
	fetchAnnouncement: () => Promise<APIResponse<Announcement[]>>;
};

export class AnnouncementService extends BaseAPI implements IStudentService {
	public async fetchAnnouncementById(id: string): Promise<APIResponse<Announcement>> {
		try {
			// const accessToken = this.authService.getToken('access-token');
			// const isAuthenticated = !(accessToken.length === 0);
			// const auth = {
			// 	authorization: accessToken,
			// };

			const response = await this.get('/announcementById/' + id, {});

			return right(Result.ok(AnnouncementDTOMapper.from(response.data.message.announcement)));
		} catch (err: any) {
			return left(err.response ? err.response.data.message : 'Connection failed');
		}
	}

	public async fetchAnnouncement(): Promise<APIResponse<Announcement[]>> {
		try {
			const accessToken = this.authService.getToken('access-token');
			const isAuthenticated = !(accessToken.length === 0);
			const auth = {
				authorization: accessToken,
			};

			const response = await this.get('/listAnnouncement', {}, isAuthenticated ? auth : null);

			return right(
				Result.ok(
					response.data.message.announcements.map(
						(announcement: AnnouncementDTO): Announcement =>
							AnnouncementDTOMapper.from(announcement)
					)
				)
			);
		} catch (err: any) {
			return left(err.response ? err.response.data.message : 'Connection failed');
		}
	}

	public async createAnnouncement(
		image1: string,
		title?: string,
		shortDescription?: string,
		description?: string,
		videoUrl?: string,
		contactEmail?: string,
		contactPerson?: string,
		scheduleTime?: string
	): Promise<APIResponse<Announcement>> {
		try {
			const response = await this.post(
				'/announcementPost',
				{
					title,
					shortDescription,
					description,
					videoUrl,
					image1,
					contactEmail,
					contactPerson,
					scheduleTime,
				},
				null,
				{
					authorization: this.authService.getToken('access-token'),
				}
			);
			return right(Result.ok(AnnouncementDTOMapper.from(response.data.message.announcement)));
		} catch (err: any) {
			return left(err.response ? err.response.data.message : 'Connection failed');
		}
	}

	public async updateAnnouncement(
		id: string,
		image1: string,
		title?: string,
		shortDescription?: string,
		description?: string,
		videoUrl?: string,
		contactEmail?: string,
		contactPerson?: string,
		scheduleTime?: string
	): Promise<APIResponse<Announcement>> {
		try {
			const response = await this.patch(
				'/announcementUpdate',
				{
					id,
					title,
					shortDescription,
					description,
					videoUrl,
					image1,
					contactEmail,
					contactPerson,
					scheduleTime,
				},
				null,
				{
					authorization: this.authService.getToken('access-token'),
				}
			);
			return right(Result.ok(AnnouncementDTOMapper.from(response.data.message.announcement)));
		} catch (err: any) {
			return left(err.response ? err.response.data.message : 'Connection failed');
		}
	}

	public async deleteAnnouncement(id: string): Promise<APIResponse<void>> {
		try {
			await this.delete('/announcementDelete', { id }, null, {
				authorization: this.authService.getToken('access-token'),
			});

			return right(Result.ok());
		} catch (err: any) {
			return left(err.response ? err.response.data.message : 'Connection failed');
		}
	}
}
