import { useAppSelector } from '@infra/redux/hook';
import { Typography } from '@material-tailwind/react';
import { AnnouncementList } from '@modules/websiteadmin/components/announcement';
import {
	fetchAnnouncements,
	selectAnnouncement,
} from '@modules/websiteadmin/redux/announcementSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function Announcement() {
	const dispatch = useDispatch();

	const announcements = useAppSelector(selectAnnouncement);

	useEffect(() => {
		dispatch(fetchAnnouncements());
	}, []);

	return (
		<div>
			<Typography variant="h3" className="pb-3">
				Announcement
			</Typography>
			<AnnouncementList announcements={announcements} />
		</div>
	);
}
