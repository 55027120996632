/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import axios from 'axios';
import ImageUploader from 'react-images-upload';

type S3UploadProps = {
	onSuccess: (url: string, uploadId: string) => void;
};

export const S3Upload = (props: S3UploadProps) => {
	const { onSuccess } = props;

	const onImage = async (_failedImages: any, successImages: string[]) => {
		try {
			const parts = successImages[0].split(';');
			const mime = parts[0].split(':')[1];
			const name = parts[1].split('=')[1];
			const data = parts[2];
			await axios
				.post('https://grace.blackforestsoftware.com/uploadAnnouncement', {
					mime,
					name,
					fileData: data,
				})
				.then((response) => {
					if (response.data.message === 'Success') {
						onSuccess(response.data.uploadUrl, response.data.id);
					}
				});
		} catch (error) {}
	};

	return (
		<ImageUploader
			key="image-uploader"
			withIcon={true}
			singleImage={true}
			withPreview={true}
			label="Maximum size file: 50MB"
			buttonText="Choose a file"
			onChange={onImage}
			imgExtension={['.jpg', '.png', '.jpeg', '.pdf', '.mp3', '.doc']}
			maxFileSize={52428800}
		></ImageUploader>
	);
};
