import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Announcement } from '../models/Announcement';
import { announcementService } from '../services';

export const updateAnnouncement = createAsyncThunk(
	'announcement/updateAnnouncement',
	async ({ id, announcement }: { id: string; announcement: AnnouncementFormData }) => {
		const result = await announcementService.updateAnnouncement(
			id,
			announcement.image1,
			announcement.title,
			announcement.shortDescription,
			announcement.description,
			announcement.videoUrl,
			announcement.contactEmail,
			announcement.contactPerson,
			announcement.scheduleTime
		);
		return result.isRight() ? result.value.getValue() : null;
	}
);

export const fetchAnnouncementById = createAsyncThunk(
	'announcement/fetchAnnouncementById',
	async (id: string) => {
		const result = await announcementService.fetchAnnouncementById(id);
		return result.isRight() ? result.value.getValue() : null;
	}
);
export const fetchAnnouncements = createAsyncThunk('announcement/fetchAnnouncements', async () => {
	const result = await announcementService.fetchAnnouncement();
	return result.isRight() ? result.value.getValue() : [];
});

export const saveAnnouncement = createAsyncThunk(
	'announcement/saveAnnouncement',
	async (newAnnouncement: AnnouncementFormData) => {
		const result = await announcementService.createAnnouncement(
			newAnnouncement.image1,
			newAnnouncement.title,
			newAnnouncement.shortDescription,
			newAnnouncement.description,
			newAnnouncement.videoUrl,
			newAnnouncement.contactEmail,
			newAnnouncement.contactPerson,
			newAnnouncement.scheduleTime
		);

		return result.isRight() ? result.value.getValue() : null;
	}
);

export const deleteAnnouncement = createAsyncThunk(
	'announcement/deleteAnnouncement',
	async (announcementId: string) => {
		const result = await announcementService.deleteAnnouncement(announcementId);

		return result.isRight() ? announcementId : null;
	}
);

export type AnnouncementFormData = {
	title?: string;
	shortDescription?: string;
	description?: string;
	videoUrl?: string;
	image1: string;
	contactEmail?: string;
	contactPerson?: string;
	scheduleTime?: string;
};

type AnnouncementState = {
	announcements: Announcement[];
	selectedAnnouncement: Announcement | null;
	status: 'idle' | 'loading' | 'complete';
};

const initialState: AnnouncementState = {
	announcements: [],
	selectedAnnouncement: null,
	status: 'idle',
};

const announcementSlice = createSlice({
	name: 'announcement',
	initialState,
	reducers: {
		addAnnouncement: (state, action: PayloadAction<Announcement>) => {
			state.announcements.push(action.payload);
		},

		getSelectedAnnouncement: (state, action: PayloadAction<string | null>) => {
			const announcementId = action.payload;
			state.selectedAnnouncement =
				announcementId != null
					? state.announcements.find((item) => item.id === announcementId) ?? null
					: null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAnnouncements.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(fetchAnnouncements.fulfilled, (state, action) => {
			state.status = 'complete';
			if (action.payload != null) {
				state.announcements = action.payload;
			}
		});
		builder.addCase(saveAnnouncement.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(saveAnnouncement.fulfilled, (state, action) => {
			state.status = 'complete';
			if (action.payload != null) {
				state.announcements.push(action.payload);
			}
		});
		builder.addCase(deleteAnnouncement.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(deleteAnnouncement.fulfilled, (state, action) => {
			state.status = 'complete';
			if (action.payload != null) {
				state.announcements = state.announcements.filter(
					(announcement) => announcement.id !== action.payload
				);
			}
		});
		builder.addCase(updateAnnouncement.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(updateAnnouncement.fulfilled, (state, action) => {
			state.status = 'complete';
			if (action.payload != null) {
				state.announcements = state.announcements.map((announcement) =>
					announcement.id === action.payload?.id ? action.payload : announcement
				);
			}
		});
		builder.addCase(fetchAnnouncementById.pending, (state) => {
			state.status = 'loading';
		});
		builder.addCase(fetchAnnouncementById.fulfilled, (state, action) => {
			state.status = 'complete';
			if (action.payload != null) {
				state.selectedAnnouncement = action.payload;
			}
		});
	},
});

type RootState = {
	announcement: AnnouncementState;
};

export const { addAnnouncement, getSelectedAnnouncement } = announcementSlice.actions;
export const selectedAnnouncement = (state: RootState): Announcement | null =>
	state.announcement.selectedAnnouncement;
export const selectAnnouncement = (state: RootState): Announcement[] =>
	state.announcement.announcements;
export const selectAnnouncementFetchStatus = (state: RootState): string =>
	state.announcement.status;

export default announcementSlice.reducer;
