import { Typography } from '@material-tailwind/react';
import { AnnouncementForm } from '@modules/websiteadmin/components/announcement';
import type { AnnouncementFormData } from '@modules/websiteadmin/redux/announcementSlice';
import { saveAnnouncement } from '@modules/websiteadmin/redux/announcementSlice';
import type { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function CreateAnnouncement() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit: SubmitHandler<AnnouncementFormData> = (data: AnnouncementFormData) => {
		dispatch(saveAnnouncement(data));
		setTimeout(() => {
			navigate(-1);
		});
	};

	return (
		<div>
			<Typography variant="h3" className="pb-3">
				Create New Announcement
			</Typography>
			<AnnouncementForm
				shortDescValue={''}
				descValue={''}
				titleValue={''}
				videoUrlValue={''}
				contactEmailValue={''}
				image1Value={''}
				contactPersonValue={''}
				onSubmit={handleSubmit}
			/>
		</div>
	);
}
